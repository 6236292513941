/**
 * Created by jiachenpan on 16/11/18.
 */

/* 是否是公司邮箱*/
export function isWscnEmail(str) {
  const reg = /^[a-z0-9](?:[-_.+]?[a-z0-9]+)*@wallstreetcn\.com$/i;
  return reg.test(str.trim());
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}


/* 验证银行卡号*/
export function validateBankCard(rule, value, callback) {
  const reg = /^[0-9]{16,19}$/
  if (reg.test(value)) {
    return callback()
  }
  return callback(new Error('银行账户格式为16-19位数字'))
}

/* 验证key*/
// export function validateKey(str) {
//     var reg = /^[a-z_\-:]+$/;
//     return reg.test(str);
// }

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
//验证手机号
export function isPhone(rule, value, callback) {
  if (!value) {
    return callback(new Error('手机号不可以为空'));
  }
  var pattern = /^1[123456789]\d{9}$/
  if (pattern.test(value)) {
    return callback()
  }
  return callback(new Error('手机号格式错误'))
}

//验证数字和字母
export function isNumbersAndLetters (rule, value, callback) {
  if (!value) {
    return callback(new Error('不可为空'));
  }
  var pattern = /^[a-zA-Z0-9]{1,20}$/
  if (pattern.test(value)) {
    return callback()
  }
  return callback(new Error('只能输入数字或字母'))
}

//验证密码
export function passwordValidate(rule, value, callback) {
  if(value){
    var pattern = /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d~!@#$%^&*]{6,20}$/
    if (pattern.test(value)) {
      return callback()
    }
    return callback(new Error('必须包含字母和数字，支持特殊字符，长度6到20位'))
  }
  return callback()
}
//验证身份证
export function idCardValidate(rule, value, callback) {
  if (!value) {
    return callback(new Error('身份证不可以为空'));
  }
  var pattern = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  if (pattern.test(value)) {
    return callback()
  }
  return callback(new Error('身份证格式错误'))
}

//验证数字
export function numberValidate(rule, value, callback) {
  if (!value) {
    return callback(new Error('不可为空'));
  }
  var pattern = /^[0-9]*$/
  if (pattern.test(value)) {
    return callback()
  }

  return callback(new Error('格式错误,请输入数字'))
}

//输入小数点后两位金额验证
//add by gaolongzhi
export function floatNumber(value, flag) {
  if (flag && parseFloat(value) >= 100) {
    value = "100"
  }
  value = value.replace(/[^\d\.]/g, '')
  //不能有多个'.'
  if (event.data === "." && value.lastIndexOf(".") !== value.indexOf(".")) {
    value = value.slice(0, value.length - 1)
  }
  //小数点后两位
  if (value.lastIndexOf(".") !== -1 && value.length >= value.lastIndexOf(".") + 3) {
    value = value.slice(0, value.lastIndexOf(".") + 3)
  }
  return value
}


//输入小数点后两位金额验证
//add by gaolongzhi
export function floatNumber1(value, flag) {
  if (flag && parseFloat(value) >= 100) {
    value = "100"
  }
  value = value.replace(/[^\d\.]/g, '')
  //不能有多个'.'
  if (event.data === "." && value.lastIndexOf(".") !== value.indexOf(".")) {
    value = value.slice(0, value.length - 1)
  }
  //小数点后两位
  if (value.lastIndexOf(".") !== -1 && value.length >= value.lastIndexOf(".") + 3) {
    value = value.slice(0, value.lastIndexOf(".") + 6)
  }
  return value
}
