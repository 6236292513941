<template>
  <div class="cardBlock">
    <div class="cardList" v-if="cardList.length">
      <div v-for="(item,index) in cardList" :key="'card'+index" class="bankCard" :class="{
        active: item.account == selected._bankAccount||item.account == selected.account,
        type_zhaoshang: item.bank_name == bankTypeArr[0],
        type_jianshe: item.bank_name == bankTypeArr[1],
        type_jiaotong: item.bank_name == bankTypeArr[2],
        type_youzheng: item.bank_name == bankTypeArr[3],
        type_gongshang: item.bank_name == bankTypeArr[4],
        type_nongye: item.bank_name == bankTypeArr[5],
        type_zhongguo: item.bank_name == bankTypeArr[6],
        type_zhongxin: item.bank_name == bankTypeArr[7],
        type_guangda: item.bank_name == bankTypeArr[8],
        type_huaxia: item.bank_name == bankTypeArr[9],
        type_minsheng: item.bank_name == bankTypeArr[10],
        type_guangfa: item.bank_name == bankTypeArr[11],
        type_pingan: item.bank_name == bankTypeArr[12],
        type_hengsheng: item.bank_name == bankTypeArr[13],
        type_zhada: item.bank_name == bankTypeArr[14],
        type_huifeng: item.bank_name == bankTypeArr[15],
        type_dongya: item.bank_name == bankTypeArr[16],
        type_zheshang: item.bank_name == bankTypeArr[17],
        type_hengfeng: item.bank_name == bankTypeArr[18],
        type_pufa: item.bank_name == bankTypeArr[19],
        type_xingye: item.bank_name == bankTypeArr[20],
        type_shanghai: item.bank_name == bankTypeArr[21],
        type_beijing: item.bank_name == bankTypeArr[22],
        alipay: item.bank_name == bankTypeArr[23],
        type_other: bankTypeArr.indexOf(item.bank_name) < 0  
      }" @click="handleCardChange(item)">
        <span class="no">{{item.secrecyAccount}}</span>
        <div v-if="untieButton">
          <span class="del" @click="handleDel(item.id)">解绑</span>
          <span class="identify" v-if="item.certification_status !=1 && item.type === 1" @click="handleIdentify(item)">认证</span>
          <span class="identify" v-if="item.type === 2" @click="handleEdit(item)">编辑</span>
        </div>
        <div v-else>
          <span class="del" v-if="item.certification_status !=1 && item.type === 1" @click="handleIdentify(item)">认证</span>
          <!--<span class="del" v-if="item.type === 2" @click="handleEdit(item)">编辑</span>-->
        </div>
      </div>
    </div>
    <template v-else>
      <empty text="暂未添加收款账号"></empty>
    </template>
    <el-dialog :close-on-click-modal="false" :append-to-body="true" :visible.sync="visible" width="500px" class="addCardBox" @closed="handleClosed" :show-close="false">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleTabClick" v-if="!isEdit">
        <el-tab-pane label="添加银行卡" name="first">
          <div>            
            <p> 请添加<span>{{user.name}}</span>的银行卡 <span>（仅支持储蓄卡）</span>
            </p>
          </div>
          <el-form :model="cardForm" :rules="cardFormRules" ref="cardForm" label-width="96px" size="small">
            <el-form-item label="银行卡号" prop="account">
              <el-input v-model="cardForm.account" placeholder="请绑定持卡人本人的银行卡" maxlength="23" @input="cardForm.account = cardForm.account.replace(/[^\d]/g,'').replace(/....(?=.)/g, '$& ')" @blur="handleAccountChange"></el-input>
            </el-form-item>
            <el-form-item label="银行名称" prop="bankName">
              <el-input v-model="cardForm.bankName" :readonly="true" />
            </el-form-item>
            <el-form-item label="预留手机号" prop="bankMobile">
              <el-input v-model="cardForm.bankMobile" placeholder="请输入银行卡预留手机号码" maxlength="11" ></el-input>
            </el-form-item>
            <el-form-item label="所属城市" prop="city">
              <el-cascader v-model="cardForm.city" :options="cityOptions" :props="{value:'id',label:'text'}" @change="handleCityChange" ref="city"></el-cascader>
            </el-form-item>
          </el-form>
          <div class="dialog-footer">
            <el-button size="small" @click="resetForm('cardForm')">取 消</el-button>
            <el-button size="small" type="primary" @click="submitForm('cardForm')">确 定</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="添加支付宝" name="second" v-if="this.hasAlipay">
          <div>
            <p> 添加<span>{{user.name}}</span>的支付宝账号 </p>
          </div>
          <el-form :model="alipayCardForm" :rules="alipayCardFormRules" ref="alipayCardForm" label-width="100px" size="small">
            <el-form-item label="支付宝账号" prop="account">
              <el-input v-model="alipayCardForm.account" placeholder="请绑定本人的支付宝账号" maxlength="50"></el-input>
            </el-form-item>
          </el-form>
          <div class="dialog-footer">
            <el-button size="small" @click="resetForm('alipayCardForm')">取 消</el-button>
            <el-button size="small" type="primary" @click="submitFormAlipay('alipayCardForm')">确 定</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
      <template v-else>
        <template v-if="accountType === bankTypeArr[23]">
            <div slot="title" class="morePadding">
              <h3>编辑支付宝账号</h3>
              <p> 请编辑<span>{{user.name}}</span>的支付宝账号 </p>
            </div>
            <div class="morePadding">
              <el-form :model="alipayCardForm" class="morePadding" :rules="alipayCardFormRules" ref="alipayCardForm" label-width="100px" size="small">
                <el-form-item label="支付宝账号" prop="account">
                  <el-input v-model="alipayCardForm.account" placeholder="请绑定本人的支付宝账号" maxlength="50"></el-input>
                </el-form-item>
              </el-form>
              <div class="dialog-footer morePadding">
                <el-button size="small" @click="resetForm('alipayCardForm')">取 消</el-button>
                <el-button size="small" type="primary" @click="submitFormAlipay('alipayCardForm'),isEdit=true">确 定</el-button>
              </div>
            </div>            
        </template>
        <template v-else>
          <div slot="title" class="morePadding">
            <h3>认证银行卡</h3>
            <p> 请认证<span>{{user.name}}</span>的银行卡 <span>（仅支持储蓄卡）</span>
            </p>
          </div>
          <div class="morePadding">
            <el-form :model="cardForm" :rules="cardFormRules" ref="cardForm" label-width="98px" size="small">
              <el-form-item label="银行卡号" prop="account">
                <el-input v-model="cardForm.account" :readonly="true" placeholder="请绑定持卡人本人的银行卡" maxlength="23" @input="cardForm.account = cardForm.account.replace(/[^\d]/g,'').replace(/....(?=.)/g, '$& ')" @blur="handleAccountChange"></el-input>
              </el-form-item>
              <el-form-item label="银行名称" prop="bankName">
                <el-input v-model="cardForm.bankName" :readonly="true" />
              </el-form-item>
              <el-form-item label="预留手机号" prop="bankMobile">
                <el-input v-model="cardForm.bankMobile" placeholder="请输入银行卡预留手机号码" maxlength="11" ></el-input>
              </el-form-item>
              <el-form-item label="所属城市" prop="city">
                 <p class="city-show">
                    {{ cardForm.provinceName }} / {{ cardForm.cityName }}
                 </p>
                <!-- <el-cascader v-model="cardForm.city" :readonly="true" :options="cityOptions" :props="{value:'id',label:'text'}" @change="handleCityChange" ref="city"></el-cascader> -->
              </el-form-item>
            </el-form>
            <div class="dialog-footer">
              <el-button size="small" @click="resetForm('cardForm')">取 消</el-button>
              <el-button size="small" type="primary" @click="submitForm('cardForm')">确 定</el-button>
            </div>
          </div>          
        </template>        
      </template>      
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  isPhone
} from "@/utils/validate";
import api from "@/api/list";
import empty from "@/components/empty/index.vue";
export default {
  components: {
    empty,
  },
  props: {
    addBankVisible: {
      type: Boolean,
      default: false,
    },

      hasAlipay: {
          type: Boolean,
      },
      canReg: {
          type: Boolean,
          default: false,
      },
      ocompanyId: {
        type: String,
      },
      untieButton: {
          type: Boolean,
          default: true,
      },
    //选择模式的比较对象
    selectInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      isEdit: false,
      cardList: [],
      bankTypeArr: [
        "招商银行",
        "建设银行",
        "交通银行",
        "邮政银行",
        "工商银行",
        "农业银行",
        "中国银行",
        "中信银行",
        "光大银行",
        "华夏银行",
        "民生银行",
        "广发银行",
        "平安银行",
        "恒生银行",
        "渣打银行",
        "汇丰银行",
        "东亚银行",
        "浙商银行",
        "恒丰银行",
        "浦东发展银行",
        "兴业银行",
        "上海银行",
        "北京银行",
        "支付宝",
      ],
      cityOptions: [],
      lastAccount: "",
      cardForm: {
        userName: "",
        bankMobile: "",
        bankName: "",
        account: "",
        provinceName: "",
        cityName: "",
        city: [],
      },
      alipayCardForm: {
        account: "",
      },
      cardFormRules: {
        account: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
          {
            min: 19,
            max: 23,
            message: "长度在 16 到 19 个字符",
            trigger: "blur",
          },
        ],
        city: [{ required: true, message: "请选择城市", trigger: "blur" }],
        bankMobile: [
          {  required: true,message: "请输入银行预留手机号码", trigger: "blur" },
          { max: 11, message: "长度在 11 个字符以内", trigger: "blur" },
          { validator: isPhone, trigger: "blur" },
        ],
      },
      alipayCardFormRules: {
        account: [
          { required: true, message: "请输入支付宝号", trigger: "blur" },
        ]
      },
      activeName: "first",
      accountType: "",
      hasAddalipay:false,//只能添加一个支付宝
    };
  },
  computed: {
    ...mapGetters(["user"]),
    visible: {
      get: function () {
        return this.addBankVisible;
      },
      set: function (v) {
        this.$emit("setVisible", v);
      },
    },
    selected: {
      get: function () {
        return this.selectInfo;
      },
      set: function (v) {},
    },
  },
  created() {
    this.getBankList();

    //获取城市列表
    api.getAllCity().then((res) => {
      if (res.status == 200) {
        this.cityOptions = res.data;
      } else {
        console.error(res.message);
      }
    });
    //获取用户名
    this.cardForm.userName = this.user.name;
  },
  methods: {
    getBankList() {
      api.getBankCardList().then((res) => {
        this.hasAddalipay=false;
        this.cardList = res.data.map((v) => {
            if(v.type===1){
              this.$set(
              v,
              "secrecyAccount",
              "****" + v.account.substring(v.account.length - 4)
              );
              return v;
            }else if(v.type===2) {
                this.hasAddalipay=true;
                let secrecyAccount = "";
                if (v.account.indexOf("@") == -1) {
                    secrecyAccount =
                        v.account.substring(0, 3) +
                        "****" +
                        v.account.substring(v.account.length - 4);
                } else {
                    secrecyAccount =
                        v.account.substring(0, 3) +
                        "****" +
                        v.account.substring(v.account.indexOf("@"));
                }

                this.$set(v, "secrecyAccount", secrecyAccount);
                return v;
            }
        }
        );
        this.$emit("setCardNumber", this.cardList.length);
      });
    },
    handleCardChange(item) {
      if (this.canReg && item.type === 2) {
        // console.log("3333", this.ocompanyId);
        api.checkSupportAliPay({ocompanyId: this.ocompanyId}, {method: "get"}).then((res) => {
          if (!res.data) {
            this.$message.error('暂不支持支付宝收款，请选择储蓄卡！');
            return;
          }
          this.handleCardChange1(item);
        });
        //请求做校验
      } else {
        this.handleCardChange1(item);
      }

    },
    handleCardChange1(item) {
      if (JSON.stringify(this.selectInfo) != "{}") {
        //没有填写银行预留手机号
        if(item.bank_mobile===""||item.bank_mobile===null){
            this.accountType = item.bank_name;
            item.bankName = item.bank_name;
            item.provinceName = item.province_name;
            item.city = [1];
            item.cityName= item.city_name;
            item.account = item.account.replace(/[^\d]/g,'').replace(/....(?=.)/g, '$& ')
            this.visible = true;
            this.isEdit = true;
            Object.assign(this.cardForm, item);
            return false
        }
        if (item.type == 1) {
          if (item.certification_status == 1) {
            Object.assign(this.selected, {
              _bankAccount: item.account,
              _bankName: item.bank_name,
              _bankMobile:item.bank_mobile,
              secrecyAccount: item.secrecyAccount,
              _type :item.type,
            });
            this.$emit("selected", this.selected);
          } else {
            this.handleIdentify(item);
          }
        }else {
          Object.assign(this.selected, {
            _bankAccount: item.account,
            _bankName: item.bank_name,
            _bankMobile:item.bank_mobile,
            secrecyAccount: item.secrecyAccount,
            _type :item.type,
          });
          this.$emit("selected", this.selected);
        }
      }
    },
    handleIdentify(item) {
      this.accountType = item.bank_name;
      item.account = item.account
        .replace(/[^\d]/g, "")
        .replace(/....(?=.)/g, "$& ");
      Object.assign(this.cardForm, item);
      this.visible = true;
      this.isEdit = true;
      this.handleAccountChange();
    },
    handleEdit(item) {
        console.log(item);
          this.accountType = item.bank_name;
          this.alipayCardForm.account = item.account.replace(/\s*/g,'');
          this.alipayCardForm.id = item.id;
          this.visible = true;
          this.isEdit = true;
      },
    handleDel(id) {
      this.$confirm("该操作将删除该收款账号，确定要解绑？", "解绑提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .deleteBankCard({}, { method: "delete", subUrl: id })
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: "解绑成功",
                });
                this.getBankList();
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: res.message,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    handleClosed() {
      this.cardForm = {
        userName: "",
        bankName: "",
        account: "",
        provinceName: "",
        cityName: "",
        city: [],
      };
      this.alipayCardForm = {
         account: "",
      };
      this.visible = false;
      this.isEdit = false;
    },
    handleCityChange() {
      let areaFullName = this.$refs["city"]
        .getCheckedNodes()[0]
        .pathLabels.join()
        .split(","); //地址名称
      this.cardForm.provinceName = areaFullName[0];
      this.cardForm.cityName = areaFullName[1];
    },
    //判断是否更改了错误的银行卡号
    submitForm(formName) {
      // if (this.lastAccount === this.cardForm.account) {
      //   this.$message({
      //     showClose: true,
      //     message: "请更换收款账号！",
      //     type: "warning",
      //   });
      // } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              if (this.lastAccount === this.cardForm.account) {
                  this.$message({
                      showClose: true,
                      message: "请更换收款账号！",
                      type: "warning",
                  });
                  return false
              }
            let temp = Object.assign({}, this.cardForm);
            temp.account = temp.account.replace(/\s/g, "");
            api.bankCardCheck(temp, { method: "POST" }).then((res) => {
              if (res.status == "200") {
                api.bankCardSave(temp, { method: "post" }).then((res) => {
                  if (res.status == "200") {
                    this.$message({
                      showClose: true,
                      message: "已成功添加",
                      type: "success",
                    });
                    this.handleClosed();
                    this.getBankList();
                    this.lastAccount = "";
                  } else if (res.status == "10001") {
                    //保存上次错误的银行卡号,并提示
                    this.lastAccount = this.cardForm.account;
                    this.$message({
                      showClose: true,
                      message: "认证失败，请核对或更换收款账号！",
                      type: "warning",
                    });
                  } else {
                    this.$message({
                      showClose: true,
                      message: res.message,
                      type: "warning",
                    });
                  }
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      // }
    },
    resetForm(formName) {
      this.handleClosed();
    },
    handleAccountChange() {
      let code = this.cardForm.account.replace(/\s/g, "");
      if (code.length >= 16 && code.length <= 19) {
        let temp = {
          code,
        };
        if (this.cardForm.id) {
          temp.id = this.cardForm.id;
        }
        api.validBankCard(temp, { method: "post" }).then((res) => {
          if (res && res.status == "200") {
            if (
              res.data.type == "" ||
              res.data.type == null ||
              res.data.type == undefined
            ) {
              this.$message({
                showClose: true,
                message: "未知错误，请更换收款账号或联系客服",
                type: "warning",
              });
            } else if (res.data.type == "储蓄卡" || res.data.type == "借记卡") {
              if (res.data.code == 1) {
                this.$message({
                  showClose: true,
                  message: res.data.name + "存在风险交易，请更换收款账号",
                  type: "warning",
                });
              } else if (res.data.code == 2) {
                this.$message({
                  showClose: true,
                  message: "暂不支持" + res.data.name + "，请更换收款账号",
                  type: "warning",
                });
              } else if (res.data.code == 3) {
                this.$message({
                  showClose: true,
                  message:
                    res.data.name +
                    "银行" +
                    res.data.bin +
                    "不支持，请更换银行卡",
                  type: "warning",
                });
              } else {
                this.cardForm.bankName = res.data.name;
              }
            } else {
              this.$message({
                showClose: true,
                message: "仅支持储蓄卡，请输入正确的储蓄卡号!!",
                type: "warning",
              });
            }
          } else {
            if (res && res.message) {
              this.$message({
                showClose: true,
                message: res.message,
                type: "warning",
              });
            }
          }
        });
      }
    },
    handleTabClick(tab, event) {
      console.log(tab, event);
    },
    //支付宝编辑提交
    submitFormAlipay(alipayCardForm) {
        if(!this.isEdit) {
            if (this.hasAddalipay) {
                this.$message({
                    showClose: true,
                    message: "只能添加一个支付宝账号",
                    type: "warning",
                });
                return false;
            }
        }
          this.$refs[alipayCardForm].validate((valid) => {
              if (valid) {
                  let temp = Object.assign({}, this.alipayCardForm);
                  api.alipaySave(temp, { method: "POST" }).then((res) => {
                      if (res.status == "200") {
                          this.$message({
                              showClose: true,
                              message: "操作成功",
                              type: "success",
                          });
                          this.handleClosed();
                          this.getBankList();
                      } else {
                          this.$message({
                              showClose: true,
                              message: res.message,
                              type: "warning",
                          });
                      }
                  });
              } else {
                  console.log("error submit!!");
                  return false;
              }
          });
      },
  },
};
</script>

<style lang="less" scoped>
.addCardBox {
  /deep/.el-dialog{
    .el-dialog__header {
      padding: 0;

      .morePadding{
        padding: 15px;
      }

      h3 {
        margin: 0 0 10px 0;
        font-size: 18px;
        line-height: 30px;
      }

      p {
        margin: 0;
        font-size: 14px;
        span {
          color: #e78320;
        }
      }
    }
    .el-dialog__body {
      padding: 0 !important;
      /deep/.morePadding{
        padding: 15px;
      }
      p {
          margin: 0;
          font-size: 14px;
          margin-bottom: 15px;
          span {
            color: #e78320;
          }
      }
      .el-tabs__nav{
        width: 100%;
        .el-tabs__item{
          text-align: center;
          width: 50%;
        }
      }  
    } 
  } 
  .city-show{
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    padding: 0 15px;
  }
}

.bankCard {
  position: relative;
  display: inline-block;
  width: 169px;
  height: 59px;
  padding: 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 8px 12px;
  cursor: pointer;
  top: 0;
  transition: top 500ms;
  -webkit-transition: top 500ms;

  &:hover {
    top: -6px;
  }

  &.active {
    top: -2px;
    &:after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 55px;
      height: 14px;
      background: url("../../../assets/selected.png") center center no-repeat;
    }
  }

  .no {
    display: block;
    color: #fff;
    font-size: 12px;
    margin-top: 16px;
  }

  .del {
    position: absolute;
    right: 10px;
    top: 10px;
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    cursor: pointer;
  }

  .identify {
    position: absolute;
    right: 52px;
    top: 10px;
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    cursor: pointer;
  }

  &.type_zhaoshang {
    background-image: url(./assets/zhaoshang.png);
  }
  &.type_jianshe {
    background-image: url(./assets/jianshe.png);
  }
  &.type_jiaotong {
    background-image: url(./assets/jiaotong.png);
  }
  &.type_youzheng {
    background-image: url(./assets/youzheng.png);
  }
  &.type_gongshang {
    background-image: url(./assets/gongshang.png);
  }
  &.type_nongye {
    background-image: url(./assets/nongye.png);
  }
  &.type_zhongguo {
    background-image: url(./assets/zhongguo.png);
  }
  &.type_zhongxin {
    background-image: url(./assets/zhongxin.png);
  }
  &.type_guangda {
    background-image: url(./assets/guangda.png);
  }
  &.type_huaxia {
    background-image: url(./assets/huaxia.png);
  }
  &.type_minsheng {
    background-image: url(./assets/minsheng.png);
  }
  // &.type_guangfa {
  //   background-image: url(./assets/guangfa.png);
  // }
  &.type_pingan {
    background-image: url(./assets/pingan.png);
  }
  &.type_hengsheng {
    background-image: url(./assets/hengsheng.png);
  }
  &.type_zhada {
    background-image: url(./assets/zhada.png);
  }
  &.type_huifeng {
    background-image: url(./assets/huifeng.png);
  }
  &.type_dongya {
    background-image: url(./assets/dongya.png);
  }
  &.type_zheshang {
    background-image: url(./assets/zheshang.png);
  }
  &.type_hengfeng {
    background-image: url(./assets/hengfeng.png);
  }
  &.type_pufa {
    background-image: url(./assets/pufa.png);
  }
  &.type_xingye {
    background-image: url(./assets/xingye.png);
  }
  &.type_shanghai {
    background-image: url(./assets/shanghai.png);
  }
  &.type_beijing {
    background-image: url(./assets/beijing.png);
  }
  &.type_other {
    background-image: url(./assets/other.png);
  }
  &.alipay {
    background-image: url(./assets/alipay.png);
  }
}
.dialog-footer{
  text-align: right;
  margin-top: 30px;
}
</style>
<style lang="less">
.addCardBox {
    .el-dialog__body {
        .morePadding{
            padding: 0 15px 15px;
        }
    }
}
</style>